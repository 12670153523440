import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import Button from "../components/Button"

export default () => {
  return (
    <Layout>
      <HeadMeta pageTitle="Installing fonts" />
      <PageHeader>Installing fonts</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Installing fonts", href: "/" },
        ]}
      />

      <p>
        To install the language font onto your computer, follow the steps below.
        Please ensure that the fonts have been uncompressed into a desired
        directory.
      </p>

      <h2>Windows</h2>

      <ol>
        <li>
          From <strong>Start menu</strong>, choose <strong>Settings</strong> ->{" "}
          <strong>Control Panel</strong>.
        </li>
        <li>
          Double-click the <strong>Fonts</strong>{" "}
          <img src="/images/font_button.gif" alt="Windows Fonts button icon" />{" "}
          button then select from the menu above <strong>File</strong> ->{" "}
          <strong>Install New Fonts</strong>.
        </li>
        <li>
          The <strong>Add Fonts</strong> box will appear. From this box, select
          the location (drive and folder) where you saved the language fonts
          that you downloaded. A list of available fonts will then appear in the
          List of fonts box. Click on <strong>Select All</strong> to highlight
          all the fonts that appear then click on <strong>OK</strong>.
        </li>
        <li>
          After finishing, close all windows. You may need to restart your
          computer.
        </li>
      </ol>
      <p>
        <Button
          onClick={e => {
            e.preventDefault()
            window.history.go(-1)
          }}
        >
          Return to the previous page
        </Button>
        <br />
        <Link to="/">Return to the Multilingual Glossary home page</Link>
      </p>
    </Layout>
  )
}
